/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Rate component styles
* Modification Log:
* License:
*/
/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 15.03.2019
* Description: Variables
* Modification Log:
* License:
*/
.rate {
  display: flex;
  align-items: center;
}
.rate .-star {
  margin-right: 5px;
}
.rate .-star svg {
  width: 22px;
  height: 20px;
  stroke: #ff9e16;
}
.rate .-star.active svg {
  fill: #ff9e16;
}
