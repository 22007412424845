/*
* Author: Dima Bozhenko
* Date Created: 15.01.2019
* Date Modified: 14.03.2019
* Description: Dashboard page styles
* Modification Log:
* License:
*/
/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 15.03.2019
* Description: Variables
* Modification Log:
* License:
*/
.dashboard {
  margin-top: 10px;
}
@media (max-width: 320px) {
  .dashboard {
    margin-top: 0;
  }
}
.dashboard.fixed {
  height: 100vh;
  overflow: hidden;
}
.dashboard .-text {
  padding-left: 10px;
}
.dashboard_head {
  margin-top: -22px;
}
.dashboard_head-wrap {
  position: relative;
  z-index: 50;
  width: 100%;
  padding: 22px 0 10px;
  background-color: #f7f2ed;
  max-width: 100%;
  min-width: 300px;
}
.dashboard_head-wrap .search-results {
  height: calc(100vh - 206px);
  overflow: auto;
}
@media (max-width: 320px) {
  .dashboard_head-wrap {
    padding: 35px 0 10px;
  }
}
