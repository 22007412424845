/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Beer Detail page styles
* Modification Log:
* License:
*/
/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 15.03.2019
* Description: Variables
* Modification Log:
* License:
*/
div.beer-detail {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
}
div.beer-detail_head {
  background-color: #fff;
  padding: 40px 10px 22px;
  position: relative;
  margin-top: -15px;
}
div.beer-detail_head .-title {
  color: #2d2d28;
  text-transform: uppercase;
  font-family: 'HWTArtz', serif;
  margin-bottom: 19px;
  font-size: 18px;
  padding-right: 38px;
}
div.beer-detail_head .-like {
  position: absolute;
  top: 40px;
  right: 22px;
}
div.beer-detail_content {
  padding: 24px 20px;
}
div.beer-detail_content .-detail {
  position: relative;
  padding-right: 100px;
  margin-bottom: 30px;
}
div.beer-detail_content .-detail_image {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}
div.beer-detail_content .-detail_image img {
  max-width: 100%;
  max-height: 100%;
}
div.beer-detail_content .-detail_title {
  font-size: 15px;
  color: #2d2d28;
  text-transform: uppercase;
  margin-bottom: 5px;
}
div.beer-detail_content .-detail_text {
  font-size: 13px;
  color: #2d2d28;
}
div.beer-detail_content .-detail_section {
  display: flex;
}
div.beer-detail_content .-detail_btn {
  border-radius: 8px;
  height: 27px;
  padding-right: 23px;
  padding-left: 23px;
  background-color: #ff9e16;
  font-size: 14px;
  border: 2px dashed #2d2d28;
  font-family: 'HWTArtz', serif;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
}
div.beer-detail_content .-characteristic_block {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
div.beer-detail_content .-characteristic_block:first-of-type {
  margin-top: 0;
}
div.beer-detail_content .-characteristic_block .-name {
  font-family: 'HWTArtz', serif;
  color: #2d2d28;
  text-transform: uppercase;
  width: 150px;
}
div.beer-detail_content .-characteristic_block .-value {
  font-size: 13px;
  color: #2d2d28;
  line-height: 1.35;
}
div.beer-detail_content .-characteristic_block.full .-name {
  width: 100%;
  margin-bottom: 12px;
}
div.beer-detail_content .-characteristic_block.full .-value {
  width: 100%;
}
div.beer-detail_content .-btn_container {
  text-align: center;
  margin-top: 60px;
}
div.beer-detail_content .-btn_container button {
  font-size: 20px;
  width: 200px;
  height: 50px;
  background-color: #ff9e16;
  border-radius: 8px;
  border: 2px dashed #2d2d28;
  color: #2d2d28;
  font-family: 'HWTArtz', serif;
  text-transform: uppercase;
}
