/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 14.03.2019
* Description: Favorites page styles
* Modification Log:
* License:
*/
/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 15.03.2019
* Description: Variables
* Modification Log:
* License:
*/
div.favorites {
  padding-left: 0;
  padding-right: 0;
}
div.favorites_head {
  background-color: #fff;
  padding-top: 35px;
  margin-top: -10px;
  padding-bottom: 10px;
}
div.favorites_head .-tabs {
  margin-top: 0;
}
div.favorites_content {
  margin-top: 10px;
}
div.favorites_content .beers .-block {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(105, 105, 105, 0.2);
}
div.favorites_content .styles {
  width: 100%;
}
div.favorites_content .styles .-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px;
}
div.favorites_content .styles .-block .-name {
  font-size: 20px;
  font-family: 'HWTArtz', serif;
  color: #2d2d28;
  text-transform: uppercase;
}
div.favorites_notice {
  padding: 0 15px;
}
