/*
* Author: Dima Bozhenko
* Date Created: 24.01.2019
* Date Modified: 15.03.2019
* Description: Like component styles
* Modification Log:
* License:
*/
/*
* Author: Dima Bozhenko
* Date Created: 04.01.2019
* Date Modified: 15.03.2019
* Description: Variables
* Modification Log:
* License:
*/
.like svg {
  width: 22px;
  height: 20px;
  stroke: #ff9e16;
}
.like.active svg {
  fill: #ff9e16;
}
